import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeartIcon } from '@heroicons/react/solid'; // Icon for favorite
import { isTokenValid } from './utils/auth';
import ImageViewer from './ImageViewer'; // Import the ImageViewer component

const fetchUsername = async () => {
  const token = localStorage.getItem('token'); // Get the JWT token from localStorage
  try {
    const response = await fetch('https://api.obadaqaf.com/fetch-username', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data.username; // Return the username from the response
  } catch (error) {
    console.error('Error fetching username:', error);
    throw new Error('Unable to fetch username');
  }
};

function Pictures() {
  const [pictures, setPictures] = useState([]);
  const [albums, setAlbums] = useState([]);
  const [currentAlbum, setCurrentAlbum] = useState(() => {
    // Read from localStorage on initial load
    return localStorage.getItem('currentAlbum') || 'All';
  });
  const [favorites, setFavorites] = useState([]);
  const [newAlbumName, setNewAlbumName] = useState('');
  const [albumModal, setAlbumModal] = useState(false);
  const [username, setUsername] = useState('');
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); // State for current page number
  const [totalPages, setTotalPages] = useState(1); // State for total pages
  const [totalItems, setTotalItems] = useState(0); // State for total items
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  // Update localStorage whenever currentAlbum changes
  useEffect(() => {
    localStorage.setItem('currentAlbum', currentAlbum);
  }, [currentAlbum]);

  useEffect(() => {
    if (isTokenValid(token)) {
      fetchUsername().then((username) => {
        setUsername(username);
        fetchAlbums(username); // Fetch albums after setting the username
        // fetchPictures(username, currentAlbum, pageNumber);
      });
    } else {
      navigate('/login');
    }
  }, [token, navigate]);//, currentAlbum, pageNumber, username]); // Add username to dependencies

  useEffect(() => {
    if (username) {
      fetchPictures(username, currentAlbum, pageNumber);
    }
  }, [username, currentAlbum, pageNumber]); // Depend on these states separately

  const fetchAlbums = async (username) => {
    try {
      const response = await fetch(`https://api.obadaqaf.com/list-albums?username=${encodeURIComponent(username)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();

      // Transform the backend response into the expected structure
      const transformedAlbums = [
        { albumId: 'all', albumName: 'All' }, // Add "All" as the first album
        ...data.albums.map((albumName, index) => ({
          albumId: index + 1, // Generate a unique ID (or use a better ID if available)
          albumName: albumName,
        }))
      ];

      setAlbums(transformedAlbums);
    } catch (error) {
      console.error('Error fetching albums:', error);
    }
  };

  const fetchPictures = async (username, albumId = '', page = 1) => {
    if (!isTokenValid(token)) {
      navigate('/login');
      return;
    }

    // If "All" is selected, do not send an albumId
    const albumParam = albumId === 'All' ? '' : encodeURIComponent(albumId);

    try {
      const response = await fetch(
        `https://api.obadaqaf.com/list-pics?albumId=${albumParam}&username=${encodeURIComponent(username)}&pageNumber=${page}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();


      setPictures(data.pictures || []);
      // setAlbums(data.albums || []);
      setTotalItems(data.totalItems || 0); // Assuming the backend returns totalItems
      setTotalPages(Math.ceil((data.totalItems || 0) / 10));
    } catch (error) {
      console.error('Error fetching pictures:', error);
    }
  };

  const createAlbum = async () => {
    try {
      const response = await fetch('https://api.obadaqaf.com/create-album', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          albumName: newAlbumName,
          username,
        }),
      });

      if (response.ok) {
        fetchAlbums(username); // Refresh the albums list after creating a new album
        fetchPictures(username);
        setAlbumModal(false);
      }
    } catch (error) {
      console.error('Error creating album:', error);
    }
  };

  const toggleFavorite = async (picture) => {
    if (!isTokenValid(token)) {
      navigate('/login');
      return;
    }

    if (favorites.includes(picture)) {
      setFavorites(favorites.filter((fav) => fav !== picture));
    } else {
      setFavorites([...favorites, picture]);
    }

    try {
      await fetch('https://api.obadaqaf.com/toggle-favorite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          pictureId: picture.pictureId,
          username,
        }),
      });
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };

  const moveToAlbum = async (pictureId, albumId) => {
    try {
      await fetch(`https://api.obadaqaf.com/move-picture`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          pictureId,
          albumId,
          username,
        }),
      });
      // setPictures((prevPictures) =>
      //   prevPictures.filter((pic) => pic.pictureId !== pictureId)
      // );
    } catch (error) {
      console.error('Error moving picture:', error);
    }
  };

  const openImageViewer = (imageUrl) => {
    setCurrentImage(imageUrl);
    setIsViewerOpen(true);
  };

  const handlePageChange = (newPageNumber) => {
    if (newPageNumber >= 1 && newPageNumber <= totalPages) {
      setPageNumber(newPageNumber);
    }
  };

  const getPaginationRange = () => {
    const range = [];
    let start = Math.max(1, pageNumber - 7);
    let end = Math.min(totalPages, start + 9);
    start = Math.max(1, end - 9);
    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-semibold text-gray-800">Your Pictures</h1>
        <button
          className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600"
          onClick={() => setAlbumModal(true)}
        >
          Create Album
        </button>
      </div>

      {albums.length > 0 && (
        <div className="mb-6">
          <h2 className="text-xl mb-4">Albums</h2>
          <div className="flex space-x-4">
            {albums.map((album) => (
              <button
                key={album.albumId}
                onClick={() => {
                  setCurrentAlbum(album.albumName);
                  setPageNumber(1); // Reset to page 1 when changing albums
                  fetchAlbums(username);
                }}
                className={`bg-white p-4 rounded-lg shadow-md ${
                  currentAlbum === album.albumName ? 'border-2 border-indigo-500' : ''
                }`}
              >
                {album.albumName}
              </button>
            ))}
          </div>
        </div>
      )}

      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-6">
        {pictures.length > 0 ? (
          pictures.map((picture, index) => (
            <div
              key={index}
              className="relative group bg-white p-4 rounded-lg shadow-md cursor-pointer"
              onClick={() => openImageViewer(picture.pictureUrl)}
            >
              <img
                src={picture.pictureUrl}
                alt={`Picture ${index + 1}`}
                className="w-full h-48 object-cover rounded-lg"
              />

              <div className="absolute top-2 right-2">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleFavorite(picture);
                  }}
                  className="bg-white p-2 rounded-full shadow-lg"
                >
                  <HeartIcon
                    className={`w-6 h-6 ${
                      favorites.includes(picture) ? 'text-red-500' : 'text-gray-400'
                    }`}
                  />
                </button>
              </div>

              <div className="mt-4">
                <label htmlFor={`album-select-${index}`} className="block mb-2">
                  Move to Album:
                </label>
                <select
                  id={`album-select-${index}`}
                  className="w-full p-2 border border-gray-300 rounded-lg"
                  onChange={(e) => {
                    e.stopPropagation(); // Stop event propagation
                    const selectedAlbum = albums.find(album => String(album.albumId) === e.target.value);
                    if (selectedAlbum) {
                      moveToAlbum(picture.pictureId, selectedAlbum.albumName);
                    }
                  }}
                  onClick={(e) => e.stopPropagation()} // Stop event propagation
                >
                  <option value="">Select Album</option>
                  {albums.map((album) => (
                    <option key={album.albumId} value={album.albumId}>
                      {album.albumName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ))
        ) : (
          <p>No pictures found.</p>
        )}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center items-center mt-6 space-x-4">
        <button
          onClick={() => handlePageChange(pageNumber - 1)}
          className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-400"
          disabled={pageNumber === 1}
        >
          Previous
        </button>
        {getPaginationRange().map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`px-3 py-1 rounded-lg ${
              pageNumber === page ? 'bg-indigo-500 text-white' : 'bg-gray-300 text-gray-800'
            }`}
          >
            {page}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(pageNumber + 1)}
          className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-400"
          disabled={pageNumber === totalPages}
        >
          Next
        </button>
      </div>

      {albumModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl mb-4">Create New Album</h2>
            <input
              type="text"
              className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
              placeholder="Album Name"
              value={newAlbumName}
              onChange={(e) => setNewAlbumName(e.target.value)}
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setAlbumModal(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={createAlbum}
                className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}

      {isViewerOpen && (
        <ImageViewer
          imageUrl={currentImage}
          onClose={() => setIsViewerOpen(false)}
        />
      )}
    </div>
  );
}

export default Pictures;