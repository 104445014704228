import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isTokenValid } from './utils/auth';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  // Check if the token exists and is valid on page load
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && isTokenValid(token)) {
      navigate('/dashboard'); // Redirect to dashboard if the token is valid
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');

    const payload = {
      username: username,
      password: password,
    };

    try {
      console.log("Attempting login request...");
    
      const response = await fetch("https://api.obadaqaf.com/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
    
      console.log("Response received:", response);
    
      if (!response.ok) {
        console.error(`HTTP Error: ${response.status} - ${response.statusText}`);
        setErrorMessage(`HTTP Error: ${response.status} - ${response.statusText}`);
        return;
      }
    
      const data = await response.json();
      console.log("Parsed response JSON:", data);
    
      // Check if the response contains a token directly
      if (data.token) {
        console.log("Token received, storing in localStorage...");
        localStorage.setItem("token", data.token); // Store token
        navigate("/dashboard"); // Redirect to dashboard
      } else {
        console.warn("No token found in response");
        setErrorMessage("Login failed, token not provided.");
      }
    } catch (error) {
      console.error("Request failed:", error);
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      console.log("Login request complete.");
      setLoading(false);
    }
    
    
  };

  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-semibold mb-6 text-center">Login</h2>
        {errorMessage && (
          <div className="mb-4 text-red-500 text-center">{errorMessage}</div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="username">
              Username
            </label>
            <input
              id="username"
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium mb-2" htmlFor="password">
              Password
            </label>
            <input
              id="password"
              type="password"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-500"
            disabled={loading}
          >
            {loading ? 'Logging in...' : 'Log In'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
