import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  PlayIcon, PauseIcon, ChevronLeftIcon, ChevronRightIcon, 
  HeartIcon, SearchIcon, RefreshIcon, SwitchHorizontalIcon 
} from '@heroicons/react/solid';
import { isTokenValid } from './utils/auth';

function Mp3() {
  const [searchTerm, setSearchTerm] = useState('');
  const [tracks, setTracks] = useState([]); // Array of track names
  const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [shuffle, setShuffle] = useState(false);
  const [repeatTrack, setRepeatTrack] = useState(false);
  const [repeatPlaylist, setRepeatPlaylist] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const audioRef = useRef(null);

  // Fetch tracks from API
  const fetchTracks = useCallback(async (query = '') => {
    setIsLoading(true); // Start loading
    if (!isTokenValid(token)) {
      navigate('/login');
      return;
    }

    try {
      const response = await fetch(
        `https://api.obadaqaf.com/list-mp3?query=${encodeURIComponent(query)}`, 
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      setTracks(data);
    } catch (error) {
      console.error('Error fetching tracks:', error);
    } finally {
      setIsLoading(false); // End loading
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchTracks();
  }, [fetchTracks]);

  // Search tracks
  const handleSearch = () => {
    if (!isTokenValid(token)) {
      navigate('/login');
      return;
    }
    fetchTracks(searchTerm.trim() ? searchTerm : '');
  };

  // Play selected track
  const playTrack = (index) => {
    if (!isTokenValid(token)) {
      navigate('/login');
      return;
    }

    if (currentTrackIndex === index) {
      setIsPlaying(!isPlaying);
    } else {
      setCurrentTrackIndex(index);
      setIsPlaying(true);
    }
  };

  // Handle Next Track
  const nextTrack = () => {
    if (shuffle) {
      setCurrentTrackIndex(Math.floor(Math.random() * tracks.length));
    } else if (currentTrackIndex !== null && currentTrackIndex < tracks.length - 1) {
      setCurrentTrackIndex((prev) => prev + 1);
    } else if (repeatPlaylist) {
      setCurrentTrackIndex(0);
    }
  };

  // Handle Previous Track
  const prevTrack = () => {
    if (currentTrackIndex !== null && currentTrackIndex > 0) {
      setCurrentTrackIndex((prev) => prev - 1);
    }
  };

  // Sync audio player with isPlaying state
  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying, currentTrackIndex]);

  // Handle track end (Auto next)
  const handleTrackEnd = () => {
    if (repeatTrack) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    } else {
      nextTrack();
    }
  };

  // Handle progress update
  const handleTimeUpdate = () => {
    setProgress(audioRef.current.currentTime);
  };

  // Handle seeking
  const handleSeek = (e) => {
    audioRef.current.currentTime = e.target.value;
    setProgress(e.target.value);
  };

  // Handle duration update
  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  // Toggle favorite
  const toggleFavorite = (track) => {
    if (favorites.includes(track)) {
      setFavorites(favorites.filter((fav) => fav !== track));
    } else {
      setFavorites([...favorites, track]);
    }
  };

  // Format time (seconds to mm:ss)
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
      {/* Header */}
      <div className="w-full max-w-4xl flex justify-between items-center mb-6">
        <h1 className="text-3xl font-semibold text-gray-800">MP3 Player</h1>
        <button
          className="flex items-center bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600"
          onClick={() => navigate(isTokenValid(token) ? '/favorites' : '/login')}
        >
          <HeartIcon className="w-5 h-5 mr-2" />
          Favorite Tracks
        </button>
      </div>

      {/* Search */}
      <div className="w-full max-w-4xl flex items-center space-x-4 mb-6">
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          placeholder="Search for a track..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={handleSearch} className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600">
          <SearchIcon className="w-5 h-5" />
        </button>
      </div>

      {/* Player Controller */}
      {currentTrackIndex !== null && (
        <div className="w-full max-w-4xl bg-white shadow-lg p-4 mb-6 rounded-lg flex flex-col items-center">
          <h2 className="text-lg font-medium">{tracks[currentTrackIndex]}</h2>

          {/* Progress Bar */}
          <div className="w-full flex items-center space-x-2">
            <span>{formatTime(progress)}</span>
            <input
              type="range"
              min="0"
              max={duration}
              value={progress}
              onChange={handleSeek}
              className="w-full"
            />
            <span>{formatTime(duration)}</span>
          </div>

          <div className="flex space-x-6 mt-4">
            <button onClick={prevTrack} disabled={currentTrackIndex === 0} className="text-gray-500 hover:text-indigo-500">
              <ChevronLeftIcon className="w-6 h-6" />
            </button>

            <button onClick={() => setIsPlaying(!isPlaying)} className="text-indigo-500 hover:text-indigo-700">
              {isPlaying ? <PauseIcon className="w-8 h-8" /> : <PlayIcon className="w-8 h-8" />}
            </button>

            <button onClick={nextTrack} disabled={currentTrackIndex === tracks.length - 1 && !repeatPlaylist} className="text-gray-500 hover:text-indigo-500">
              <ChevronRightIcon className="w-6 h-6" />
            </button>

            <button onClick={() => setShuffle(!shuffle)} className={`${shuffle ? 'text-indigo-500' : 'text-gray-500'} hover:text-indigo-700`}>
              <SwitchHorizontalIcon className="w-6 h-6" />
            </button>

            <button onClick={() => setRepeatTrack(!repeatTrack)} className={`${repeatTrack ? 'text-indigo-500' : 'text-gray-500'} hover:text-indigo-700`}>
              <RefreshIcon className="w-6 h-6" />
            </button>

            <button onClick={() => setRepeatPlaylist(!repeatPlaylist)} className={`${repeatPlaylist ? 'text-indigo-500' : 'text-gray-500'} hover:text-indigo-700`}>
              <RefreshIcon className="w-6 h-6 rotate-180" />
            </button>
          </div>

          {/* Hidden Audio Element */}
          <audio
            ref={audioRef}
            src={`https://cdn.obadaqaf.com/mp3/${tracks[currentTrackIndex]}`}
            onEnded={handleTrackEnd}
            onTimeUpdate={handleTimeUpdate}
            onLoadedMetadata={handleLoadedMetadata}
            hidden
          />
        </div>
      )}

      {/* Track List */}
      <div className="w-full max-w-4xl">
        {isLoading ? (
          <p>Loading tracks...</p>
        ) : tracks.length > 0 ? (
          tracks.map((track, index) => (
            <div key={index} className="flex justify-between items-center bg-white p-4 mb-2 rounded-lg shadow-md">
              <span>{track}</span>
              <div className="flex space-x-4">
                <button
                  onClick={() => playTrack(index)}
                  className="text-indigo-500 hover:text-indigo-700"
                >
                  {currentTrackIndex === index && isPlaying ? <PauseIcon className="w-6 h-6" /> : <PlayIcon className="w-6 h-6" />}
                </button>
                <button onClick={() => toggleFavorite(track)}>
                  <HeartIcon
                    className={`w-6 h-6 ${
                      favorites.includes(track) ? 'text-red-500' : 'text-gray-400'
                    }`}
                  />
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No tracks found.</p>
        )}
      </div>
    </div>
  );
}

export default Mp3;
